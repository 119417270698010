import { createGlobalStyle, css } from 'styled-components';

const baseFontStyles = css`
  color: inherit;
`;

export const headLine1 = css`
  ${(props) => props.theme.fontStyleBold}
  font-size: 48px;
  line-height: 58px;

  @media (max-width: 1400px) {
    font-size: 42px;
    line-height: 52px;
  }

  @media (max-width: 576px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const headLine2 = css`
  ${(props) => props.theme.fontStyleBold}
  font-size: 32px;
  line-height: 40px;

  @media (max-width: 576px) {
    font-size: 26px;
    line-height: 34px;
  }
`;

export const headLine3 = css`
  ${(props) => props.theme.fontStyleMedium}
  font-size: 23px;
  line-height: 32px;

  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const headLine4 = css`
  ${(props) => props.theme.fontStyleMedium}
  font-size: 18px;
  line-height: 26px;
`;

export const boldHeadLine4 = css`
  ${(props) => props.theme.fontStyleBold}
  font-size: 18px;
  line-height: 26px;
`;

export const headLine5 = css`
  ${(props) => props.theme.fontStyleMedium}
  font-size: 16px;
  line-height: 26px;
`;

export const para1 = css`
  ${(props) => props.theme.fontStyleRegular}
  font-size: 18px;
  line-height: 26px;
`;

export const para2 = css`
  ${(props) => props.theme.fontStyleRegular}
  font-size: 16px;
  line-height: 26px;
`;

export const captions = css`
  ${(props) => props.theme.fontStyleRegular}
  font-size: 16px;
  line-height: 25.6px;
  opacity: 0.4;
`;

export const linkText = css`
  ${(props) => props.theme.fontStyleRegular}
  font-size: 14px;
  line-height: 21px;
`;

export const disclaimerText = css`
  ${(props) => props.theme.fontStyleRegular}
  font-size: 14px;
  line-height: 21px;
  opacity: 0.4;
`;

export const readmoreText = css`
  ${(props) => props.theme.fontStyleMedium}
  font-size: 16px;
  line-height: 26px;
`;

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box; 
  }

  html {
    overflow-x: hidden;
    
    // This would only be applied for hamburger menu active state
    &.hidden-header {
    @media (max-width: 1024px) {
        overflow: hidden !important;
      }
    }
  }

  body {
    ${(props) => props.theme.fontStyleRegular};
    padding: 0;
    margin: 0;

    * { scroll-margin-top: 100px; }

    &.using-mouse {
      * {
        &:focus {
          outline: none !important;
        }
      }
    }
  }

  a {
    ${baseFontStyles}
    text-decoration: none;
  }
  span {
    ${(props) => props.theme.fontStyleLight};
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  ul {
    list-style-type: none;
  }
  .wrapper {
    max-width: 1260px;
    width: 85%;
    margin: 0 auto;
  }
  h5 {
    ${(props) => props.theme.fontStyleLight};
  }
  h3 {
    ${(props) => props.theme.fontStyleLight};
  }

  // removed tooltip that shows original text on translated page by google translate
  #goog-gt-tt {
    display: none !important;
  }
  .goog-tooltip {
    display: none !important;
  }
  .goog-tooltip:hover {
    display: none !important;
  }
  .goog-text-highlight {
    background-color: transparent !important;
    border: none !important; 
    box-shadow: none !important;
  }
  a.cta {
    color: ${(props) => props.theme.color.bgPrimary};
    &:hover {
      text-decoration: underline;
    }
  }
  .form-consent {
    margin-top: 5px !important;
    display: flex;
    align-items: baseline;
    position: relative;
    p{
      margin-left: 10px;
      color: ${(props) => props.theme.color.textLight};
      ${para2}
    }
  }
  .ohnohoney {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }
`;

export const lineClamp = () => css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: 768px) {
    -webkit-line-clamp: 4;
  }
`;

export const hoverEffect = (color = 'orangeUnderline') => css`
  text-decoration: none;
  text-underline-offset: 2px;
  text-decoration-color: transparent;
  text-decoration-skip-ink: none;
  transition: text-decoration 0.2s;
  @media (min-width: 1024px) {
    &:hover {
      text-decoration: underline;
      text-decoration-color: ${(props) => props.theme.color[color]};
      text-decoration-thickness: 5px;
    }
  }
`;

export const hoverImageEffect = (ratio) => css`
  border: 1px solid rgba(0, 0, 0, 20%);
  aspect-ratio: ${ratio || '16 / 9'};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  & > div {
    width: 100%;
    height: 100%;
    transition: transform ease 0.6s;
  }

  @media (min-width: 1024px) {
    &:hover > div {
      transform: scale(1.05);
    }
  }
`;

export default GlobalStyle;
