/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import useIsClient from '../../hooks/useIsClient';
import {
  TranslateContainer,
  SelectedLanguage,
  ListContainer,
  LangContainer,
} from './translate.styled';
import { IoIosArrowDown } from 'react-icons/io';
import UnitedStatesFlag from '../../assets/svg/usa-flag-1.svg';
import ChinaFlag from '../../assets/svg/Flag_of_Peoples_Republic_of_China.svg';
import FranceFlag from '../../assets/svg/Flag_of_France.svg';
import JapanFlag from '../../assets/svg/Flag_of_Japan.svg';
import PortugalFlag from '../../assets/svg/Flag_of_Portugal.svg';
import RussiaFlag from '../../assets/svg/Flag_of_Russia.svg';
import SpainFlag from '../../assets/svg/Flag_of_Spain.svg';
import UnitedArabEmiratesFlag from '../../assets/svg/Flag_of_United_Arab_Emirates.svg';

const GoogleTranslate = ({ languageRef, selectFlag, setSelectFlag, selectLangVal, setSelectLangVal }) => {
  const { isClient, key } = useIsClient();
  const [isListVisible, setIsListVisible] = useState(false);
  const outsideClickRef = useRef();

  const langList = [
    'ar',
    'zh-CN',
    'zh-TW',
    'en',
    'fr',
    'ja',
    'pt',
    'ru',
    'es',
  ].join(',');

  const selectLangList = [
    { value: 'ar', name: 'Arabic', flag: <UnitedArabEmiratesFlag /> },
    { value: 'zh-CN', name: 'Chinese (Simplified)', flag: <ChinaFlag /> },
    { value: 'zh-TW', name: 'Chinese (Traditional)', flag: <ChinaFlag /> },
    { value: 'en', name: 'English', flag: <UnitedStatesFlag /> },
    { value: 'fr', name: 'French', flag: <FranceFlag /> },
    { value: 'ja', name: 'Japanese', flag: <JapanFlag /> },
    { value: 'pt', name: 'Portuguese', flag: <PortugalFlag /> },
    { value: 'ru', name: 'Russian', flag: <RussiaFlag /> },
    { value: 'es', name: 'Spanish', flag: <SpainFlag /> },
  ];

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      { pageLanguage: 'en', includedLanguages: langList },
      'google_translate_element',
    );
  };

  const handleLanguageChange = (langValue, flagValue) => {
    setSelectLangVal(langValue);
    setSelectFlag(flagValue);
  };

  const handleDropdown = (e) => {
    e.preventDefault();
    setIsListVisible(!isListVisible);
  };

  const handleOutsideClick = (e) => {
    if (
      isListVisible &&
      outsideClickRef.current &&
      !outsideClickRef.current.contains(e.target)
    ) {
      setIsListVisible(false);
    }
  };

  // handle outside click event for dropdown
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isListVisible]);

  const handleCustomLanguageChange = (langValue) => {
    // Manually change the selected value in Google Translate selector
    const googleTranslator = document.querySelector('.goog-te-combo');
    if (googleTranslator) {
      googleTranslator.value = langValue;
      const event = new Event('change');
      googleTranslator.dispatchEvent(event);
      setIsListVisible(false);
    }
  };

  useEffect(() => {
    const cookieData = document.cookie.match(
      new RegExp(`(^| )googtrans=([^;]+)`),
    )?.[2];
    const selectedLanguage = selectLangList.find(
      (item) => cookieData?.split('/en/')[1] === item.value,
    );

    if (selectedLanguage) {
      setSelectLangVal(selectedLanguage.value);
      setSelectFlag(selectedLanguage.flag);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    const cond1 = document.getElementById('goog-gt-tt') || false;
    const cond2 = document.querySelector('.goog-te-spinner-pos') || false;

    script.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
    );
    script.defer = true;
    script.id = 'gTranslateTest';

    document.body.appendChild(script);

    window.googleTranslateElementInit = googleTranslateElementInit;
    return () => {
      document.body.removeChild(script);
      window.googleTranslateElementInit = null;
      cond1 && cond1.remove();
      cond2 && cond2.remove();
    };
  }, []);

  if (!isClient) return null;

  return (
    <>
      <TranslateContainer key={key}>
        <div id='google_translate_element' ref={languageRef}></div>
      </TranslateContainer>
      <LangContainer ref={outsideClickRef}>
        <SelectedLanguage
          className={`select-lang ${isListVisible ? 'rotate-icon' : ''}`}
        >
          <a
            className='skiptranslate'
            href='#'
            data-lang-value={selectLangVal}
            onClick={(e) => handleDropdown(e)}
          >
            <span className='flag-ctn'>
              {selectFlag}
              <span>
                {selectLangList.find((item) => item.value === selectLangVal).name}
              </span>
            </span>
            <IoIosArrowDown
              className={`down-arrow ${isListVisible ? 'rotate180' : ''}`}
            />
          </a>
        </SelectedLanguage>
        <ListContainer isListVisible={isListVisible} className='language-list skiptranslate'>
          {selectLangList.map((lang) => {
            return (
              <li key={lang.value}>
                <a
                  className='skiptranslate'
                  href='#'
                  onClick={() => {
                    handleLanguageChange(lang.value, lang.flag);
                    handleCustomLanguageChange(lang.value);
                  }}
                >
                  {lang.flag}
                  {lang.name}
                </a>
              </li>
            );
          })}
        </ListContainer>
      </LangContainer>
    </>
  );
};

export default GoogleTranslate;
