import React from 'react';
import { Link } from 'gatsby';
import { IoLogoLinkedin } from 'react-icons/io5';
import { FaFacebookF } from 'react-icons/fa';
import { BsInstagram, BsYoutube } from 'react-icons/bs';
import { FaXTwitter } from 'react-icons/fa6';
import { FooterContainer, SocialLinks, FooterMessage, FooterTop, FooterBottom } from './Footer.styled';
import { footerNav } from '../../utils/site-config';

const Footer = () => {
  return (
    <FooterContainer data-testid='footerElem'>
      <FooterTop>
        <ul className='wrapper'>
          {
            footerNav.map((item) => (
              Array.isArray(item.subNav) && item.subNav.length > 0 ?
                (
                  <li>
                    {
                      item.url ? (
                        <Link className='main-menu main-menu-hover' title={item.title} to={item.url}>{item.title}</Link>
                      ) : (
                        <span className='main-menu'>{item.title}</span>
                      )
                    }
                    <ul className='sub-menu'>
                      {
                        item.subNav.map((sub) => (
                          <li>
                            <Link to={sub.url}>{sub.title}</Link>
                          </li>
                        ))
                      }
                    </ul>
                  </li>
                ) : (
                  <li>
                    <Link to={item.url} title={item.title}>{item.title}</Link>
                  </li>
                )
            ))
          }
        </ul>
      </FooterTop>
      <FooterBottom>
        <div className='wrapper'>
          <FooterMessage className='panorama'>
            Pandemic Action Network is powered by{' '}
            <a
              href='https://www.panoramaglobal.org/ '
              title='Panorama'
              target='_blank'
              rel='noreferrer'
            >
              Panorama Global
            </a>
            , a platform for social change dedicated to solving global problems through radical collaboration.
          </FooterMessage>
          <SocialLinks className='social-icon'>
            <li>
              <a
                href='https://twitter.com/PandemicAction'
                target='_blank'
                title='Twitter'
                rel='noreferrer'
              >
                <FaXTwitter />
              </a>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/company/pandemicactionnetwork/'
                title='LinkedIn'
                target='_blank'
                rel='noreferrer'
              >
                <IoLogoLinkedin />
              </a>
            </li>
            <li>
              <a
                href='https://www.facebook.com/PandemicActionNetwork/'
                title='Facebook'
                target='_blank'
                rel='noreferrer'
              >
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/pandemicactionnetwork/'
                title='Instagram'
                target='_blank'
                rel='noreferrer'
              >
                <BsInstagram />
              </a>
            </li>
            <li className='youtube-icon'>
              <a
                href='https://www.youtube.com/channel/UC7ivf0adjuDYtNfHfVqZ3Kw'
                target='_blank'
                title='YouTube'
                rel='noreferrer'
              >
                <BsYoutube />
              </a>
            </li>
          </SocialLinks>
        </div>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
